// Chakra imports
import {
    Box,
    Flex,
    Text,
    Select,
    Input,
    InputGroup,
    InputRightElement,
    IconButton,
    useColorModeValue,
    useToast,
    ButtonGroup,
    Button,
    Popover,
    PopoverTrigger,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverContent,
    PopoverBody,
    Divider,
    Checkbox,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import OrderTableRow from "components/Shop/OrderTableRow";
import React, { useEffect, useState } from "react";
import { getRequest } from "API";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Swal from "sweetalert2";
import PageNumberSelector from "components/PageNumberSelector";
const config = require("../../API/config.json");

const statusOptions = [
    { value: "", label: "Wszystko" },
    { value: "new", label: "Nowe" },
    { value: "done", label: "Zrealizowane" },
    { value: "sent", label: "Wysłane" },
    { value: "cancelled", label: "Anulowane" },
];

const paymentStatusOptions = [
    { value: "", label: "Wszystko" },
    { value: "STARTED", label: "Rozpoczęte" },
    { value: "PENDING", label: "W trakcie" },
    { value: "COMPLETED", label: "Opłacone" },
    { value: "CANCELED", label: "Anulowane" },
];

const sortByOptions = [
    { value: "configId", label: "Data" },
    { value: "total", label: "Cena" },
];

function Orders() {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");
    const [selectedSortBy, setSelectedSortBy] = useState("configId");
    const [selectedDirection, setSelectedDirection] = useState("desc");
    const [searchQuery, setSearchQuery] = useState("");
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalCount, setTotalOrders] = useState(1);

    const [withLabel, setWithLabel] = useState("");

    const textColor = useColorModeValue("gray.700", "white");
    const toast = useToast();

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                setIsLoading(true);
                const url = `${config.host}/orders`;

                // Apply filters as query parameters
                const params = new URLSearchParams();
                if (selectedStatus) {
                    params.append("status", selectedStatus);
                }
                if (selectedPaymentStatus) {
                    params.append("payment_status", selectedPaymentStatus);
                }
                if (selectedSortBy) {
                    params.append("sort_by", selectedSortBy);
                }
                if (selectedDirection) {
                    params.append("sort_direction", selectedDirection);
                }
                if (searchQuery) {
                    params.append("search", searchQuery);
                }
                if (withLabel) {
                    params.append("withlabel", withLabel);
                }
                params.append("page", currentPage.toString());

                const ordersData = await getRequest(`${url}?${params.toString()}`);
                setOrders(ordersData.orders);
                setTotalPages(ordersData.totalPages);
                setTotalOrders(ordersData.totalCount);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
                toast({
                    title: "Error",
                    description: "Failed to fetch orders.",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
                setIsLoading(false);
            }
        };

        fetchOrders();
    }, [selectedStatus, selectedPaymentStatus, selectedSortBy, selectedDirection, searchQuery, currentPage, withLabel]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const downloadLabels = async (type) => {
        if (type === "done") {
            try {
                // Open swal with loading spinner
                const swalOrder = Swal.fire({
                    title: `Tworzenie Etykiet dla zrealizowanych... (to jeszcze nie działa)`,
                    allowOutsideClick: false,
                });

                //const labelRequest = await getRequest(`${config.host}/createlabels?for=done`);
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="20px">
                <CardHeader p="6px 0px 22px 0px">
                    <Text fontSize="xl" color={textColor} fontWeight="bold">
                        Zamówienia ({totalCount})
                    </Text>
                    <Flex align="center" mt={2} w="100%">
                        <Text mr={2}>Status płatności:</Text>
                        <Select value={selectedPaymentStatus} onChange={(e) => setSelectedPaymentStatus(e.target.value)} size="sm" w="150px" ml={2} mr={3}>
                            {paymentStatusOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Select>
                        <Text mr={2}>Status zamówienia:</Text>
                        <Select value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)} size="sm" w="120px">
                            {statusOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Select>

                        <Text mx={4}>Sortowanie:</Text>
                        <Select value={selectedSortBy} onChange={(e) => setSelectedSortBy(e.target.value)} size="sm" w="120px">
                            {sortByOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Select>
                        <Select value={selectedDirection} onChange={(e) => setSelectedDirection(e.target.value)} size="sm" w="120px">
                            <option value="desc">Malejąco</option>
                            <option value="asc">Rosnąco</option>
                        </Select>
                        <Flex ml="auto" align="center" gap="10px">
                            <Popover>
                                <PopoverTrigger>
                                    <Button>
                                        <AiOutlineInfoCircle cursor="pointer" />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverHeader>INSTRURUUUKCJA</PopoverHeader>
                                    <PopoverBody>
                                        Szukanie po ID: <b>#id</b> np. <b>#1234</b>
                                        <Divider my="10px" />
                                        Szukanie bez <b>#</b> też będzie działać ale wtedy szuka w:
                                        <br /> - Imię i nazwisko
                                        <br /> - Email
                                        <br /> - Id zamówienia
                                        <Divider my="10px" />
                                        Żeby zrestartować, usuń szukajke i kliknij Szukaj
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                            <InputGroup ml="auto" w="300px">
                                <Input placeholder="Szukaj" value={search} onChange={(e) => setSearch(e.target.value)} />
                                <InputRightElement>
                                    <IconButton aria-label="Search" size="sm" icon={<SearchIcon />} onClick={() => setSearchQuery(search)} />
                                </InputRightElement>
                            </InputGroup>
                        </Flex>
                    </Flex>
                </CardHeader>
                <CardBody>
                    {isLoading ? (
                        <Text>Ładowanie...</Text>
                    ) : (
                        <>
                            {orders.map((row, index, arr) => {
                                return <OrderTableRow order={row} isLast={index === arr.length - 1 ? true : false} />;
                            })}
                            {totalPages > 1 && <PageNumberSelector currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />}
                        </>
                    )}
                </CardBody>
            </Card>
        </Flex>
    );
}

/*

                <Flex justify="center" mt={4}>
                  <ButtonGroup isAttached variant="outline" size="sm">
                    {Array.from(Array(totalPages), (e, i) => {
                      const pageNumber = i + 1;
                      return (
                        <Button key={pageNumber} isActive={pageNumber === currentPage} onClick={() => handlePageChange(pageNumber)}>
                          {pageNumber}
                        </Button>
                      );
                    })}
                  </ButtonGroup>
                </Flex>

<Flex>
  <Select value={selectedDirection} onChange={(e) => downloadLabels(e.target.value)} size="xs" w="auto">
    <option value="desc">Pobieranie Etykiet</option>
    <option value="selected">Pobierz Etykiety dla zaznaczonych</option>
    <option value="done">Pobierz Etykiety dla zrealizowanych</option>
  </Select>

  <Checkbox ml="5" onChange={(e) => setWithLabel(e.target.checked)}>
    Z zrobioną etykietą
  </Checkbox>
</Flex>
          
*/

export default Orders;
