import React, { useState, useEffect } from "react";
import axios from "axios";
// Chakra imports
import { Box, Flex, Button, FormControl, FormLabel, HStack, Input, Icon, Link, Switch, Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/signInImage.png";

import { createClient } from "@supabase/supabase-js";
import settings from "../../settings.json";
import { getUserData } from "variables/userdata";
import { useHistory } from "react-router-dom";

const supabase = createClient(settings.supabase.url, settings.supabase.apiKey);

function SignIn() {
  const [isBusy, setBusy] = useState(true);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const signInWithEmail = async () => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    setUser(data.user);
    console.log(data);
  };

  const getUser = async () => {
    const user = await getUserData();
    if (!user.error) {
      setUser(user);
      history.push("/admin");
    }

    setBusy(false);
  };

  useEffect(() => {
    getUser();
  }, [user]);

  if (isBusy) {
    return <Text>Ładowanie...</Text>;
  }

  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  return (
    <Flex position="relative" mb="40px">
      <Flex minH={{ md: "1000px" }} h={{ sm: "initial", md: "75vh", lg: "85vh" }} w="100%" maxW="1044px" mx="auto" justifyContent="space-between" mb="30px" pt={{ md: "0px" }}>
        <Flex w="100%" h="100%" alignItems="center" justifyContent="center" mb="60px" mt={{ base: "50px", md: "20px" }}>
          <Flex
            zIndex="2"
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="15px"
            p="40px"
            mx={{ base: "100px" }}
            m={{ base: "20px", md: "auto" }}
            bg={bgForm}
            boxShadow={useColorModeValue("0px 5px 14px rgba(0, 0, 0, 0.05)", "unset")}
          >
            <Text fontSize="xl" color={textColor} fontWeight="bold" textAlign="center" mb="22px">
              Log In
            </Text>
            <FormControl>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Name
              </FormLabel>
              <Input variant="auth" fontSize="sm" ms="4px" type="text" placeholder="Your full name" mb="24px" size="lg" onChange={(e) => setEmail(e.target.value)} />
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Password
              </FormLabel>
              <Input variant="auth" fontSize="sm" ms="4px" type="password" placeholder="Your password" mb="24px" size="lg" onChange={(e) => setPassword(e.target.value)} />
              <FormControl display="flex" alignItems="center" mb="24px">
                <Switch id="remember-login" colorScheme="blue" me="10px" onChange={(e) => setRememberMe(e.target.checked)} />
                <FormLabel htmlFor="remember-login" mb="0" fontWeight="normal">
                  Remember me
                </FormLabel>
              </FormControl>
              <Button fontSize="10px" variant="dark" fontWeight="bold" w="100%" h="45" mb="24px" onClick={signInWithEmail}>
                Log In
              </Button>
            </FormControl>
          </Flex>
        </Flex>
        <Box overflowX="hidden" h="100%" w="100%" left="0px" position="absolute" bgImage={signInImage}>
          <Box w="100%" h="100%" bgSize="cover" bg="blue.500" opacity="0.8"></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
