import {
  Avatar,
  Badge,
  Flex,
  Tag,
  TagLabel,
  Td,
  Text,
  Tr,
  useColorModeValue,
  HStack,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Input,
  Image,
  Divider,
} from "@chakra-ui/react";

import { Textarea, InputGroup, InputLeftAddon, NumberInput, NumberInputField } from "@chakra-ui/react";
import { Select, SelectButton, VStack, Checkbox } from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import moment from "moment";
import Choices from "choices.js";

import ProductColors from "./components/ProductColors";
import ProductSizes from "./components/ProductSizes";
import { postRequest } from "API";
import { useRef } from "react";
import MultiSelectionInput from "./components/MultiSelection";
import Swal from "sweetalert2";
const config = require("../../API/config.json");

require("moment/locale/pl");

function ProductTableRow(props) {
  const { attributes, isLast, selected, onSelect } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [product, setProduct] = useState(props.product);
  const [isBusy, setBusy] = useState(true);

  const [isDeleted, setIsDeleted] = useState(false);

  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [description, setDescription] = useState();
  const [price, setPrice] = useState();
  const [salePrice, setSalePrice] = useState();
  const [categories, setCategories] = useState();
  const [tags, setTags] = useState();

  const [globalColors, setGlobalColors] = useState();
  const [xsColors, setXsColors] = useState();
  const [sColors, setSColors] = useState();
  const [mColors, setMColors] = useState();
  const [lColors, setLColors] = useState();
  const [xlColors, setXlColors] = useState();
  const [xxlColors, setXxlColors] = useState();

  function loadData(prd) {
    setBusy(true);
    setProduct(prd);
    setBusy(false);

    setGlobalColors(
      prd.colors.map((color) => {
        return { configId: color?.id, label: color?.name, value: color?.id };
      })
    );

    setXsColors(
      prd.xs_colors.map((color) => {
        return { configId: color?.id, label: color?.name, value: color?.id };
      })
    );

    setSColors(
      prd.s_colors.map((color) => {
        return { configId: color?.id, label: color?.name, value: color?.id };
      })
    );

    setMColors(
      prd.m_colors.map((color) => {
        return { configId: color?.id, label: color?.name, value: color?.id };
      })
    );

    setLColors(
      prd.l_colors.map((color) => {
        return { configId: color?.id, label: color?.name, value: color?.id };
      })
    );

    setXlColors(
      prd.xl_colors.map((color) => {
        return { configId: color?.id, label: color?.name, value: color?.id };
      })
    );

    setXxlColors(
      prd.xxl_colors.map((color) => {
        return { configId: color?.id, label: color?.name, value: color?.id };
      })
    );

    setName(prd.name);
    setImage(prd.image);
    setDescription(prd.description);
    setPrice(prd.price);
    setSalePrice(prd.sale_price);
    setCategories(prd.categories);
    setTags(prd.tags);
  }

  useEffect(() => {
    loadData(props.product);
  }, [props.product]);

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const currentDate = new Date();
  const lastUpdateDate = moment(product.updatedAt).from(currentDate);

  const updateProduct = async (data) => {
    const url = `${config.host}/updateProducts`;
    const bodyData = { data: { ...data, where: { configId: product.configId } } };

    try {
      const request = await postRequest(url, bodyData);

      setProduct((prevProduct) => ({
        ...prevProduct,
        ...data,
      }));

      console.log(data);
    } catch (error) {}
  };

  const handleSave = () => {
    const data = {
      name: name,
      description: description,
      price: price,
      sale_price: parseFloat(salePrice) || 0,
      tags: tags,
      colors: globalColors.map((item) => `${item.configId}`).join(","),
      xs_colors: xsColors.map((item) => `${item.configId}`).join(","),
      s_colors: sColors.map((item) => `${item.configId}`).join(","),
      m_colors: mColors.map((item) => `${item.configId}`).join(","),
      l_colors: lColors.map((item) => `${item.configId}`).join(","),
      xl_colors: xlColors.map((item) => `${item.configId}`).join(","),
      xxl_colors: xxlColors.map((item) => `${item.configId}`).join(","),
    };

    updateProduct(data);
    onClose();
  };

  const deleteProduct = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await postRequest(`${config.host}/deleteProduct`, { productId: product.configId });
        setIsDeleted(true);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const colors = attributes?.colors?.map((color) => {
    return { configId: color.configId, label: color?.name, value: color.configId };
  });

  if (isBusy) {
    return "Loading...";
  }

  return (
    <>
      <Tr key={product.configId} display={isDeleted && "none"}>
        <Td minWidth={{ sm: "250px" }} pl="0px" borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Checkbox mr="12px" isChecked={selected} onChange={onSelect} />
            <Avatar src={product.image} w="100px" h="100px" borderRadius="12px" me="18px" />
            <Flex direction="column">
              <Flex>
                <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                  {product.name}
                </Text>
                <Text fontSize="xs">{product.configId}</Text>
              </Flex>
              <Flex direction="column" gap="10px" mt="5">
                <ProductColors product={product} />
                <ProductSizes product={product} />
              </Flex>
            </Flex>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          {product.sale_price ? (
            <Text {...attributes}>
              {product.sale_price} PLN
              <Text as="span" fontSize="sm" fontWeight="normal" color="gray.600" textDecoration="line-through" ml="2">
                {product.price} PLN
              </Text>
            </Text>
          ) : (
            <Text m="0" {...attributes}>
              {product.price} PLN
            </Text>
          )}
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null} maxW="200px">
          <Flex direction="column">
            <HStack spacing={2} mb={2}>
              {product.categories?.map((category) => (
                <Tag size={"md"} key={category?.configId} variant="outline" colorScheme="blue">
                  <TagLabel>{category?.name}</TagLabel>
                </Tag>
              ))}
            </HStack>
            {product.tags && (
              <Box>
                {product?.tags?.split(",")?.map((tag, index) => (
                  <Tag size="sm" key={index} variant="outline" colorScheme="orange" mr="5px" mb="5px">
                    <TagLabel w="100%">{tag}</TagLabel>
                  </Tag>
                ))}
              </Box>
            )}
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Badge
            bg={product.public === true ? "green.400" : "red.400"}
            color="white"
            fontSize="16px"
            p="3px 10px"
            borderRadius="8px"
            onClick={() => updateProduct({ public: product.public ? false : true })}
            cursor="pointer"
          >
            {product.public ? "Publiczne" : "Niepubliczne"}
          </Badge>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {lastUpdateDate}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Button p="0px" bg="transparent" variant="no-effects">
            <Text fontSize="md" color="gray.400" fontWeight="bold" cursor="pointer" onClick={onOpen}>
              Edit
            </Text>
          </Button>
          <Button p="0px" bg="transparent" variant="no-effects">
            <Text fontSize="md" color="red.400" fontWeight="bold" cursor="pointer" onClick={deleteProduct}>
              Delete
            </Text>
          </Button>
        </Td>
      </Tr>

      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Flex>
              <Box flex="0 0 200px" borderRight="1px solid #cccfd4" pr={5} pt={5}>
                <Image src={image} alt="Kliknij aby ustawić zdjęcie" borderRadius="md" />
                <Box mt={3}>
                  <Text>Kategorie</Text>
                </Box>
                <Box mt={3}>
                  <Text>Tagi</Text>
                </Box>
              </Box>
              <Box flex="1" p={5}>
                <Box mb={3}>
                  <Text>Nazwa przedmiotu</Text>
                  <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </Box>
                <Box mb={3}>
                  <Text>Opis</Text>
                  <Textarea rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
                </Box>
                <Flex mb={3}>
                  <Box flex="1" mr={3}>
                    <Text>Cena</Text>
                    <InputGroup>
                      <InputLeftAddon children="PLN" />
                      <NumberInput value={price} min={0} onChange={(e) => setPrice(e)}>
                        <NumberInputField required />
                      </NumberInput>
                    </InputGroup>
                  </Box>
                  <Box flex="2" mr={3}>
                    <Text>Cena Promocyjna</Text>
                    <InputGroup>
                      <InputLeftAddon children="PLN" />
                      <NumberInput value={salePrice} onChange={(e) => setSalePrice(e)}>
                        <NumberInputField required />
                      </NumberInput>
                    </InputGroup>
                  </Box>
                </Flex>
                <Divider my={5} />
                <Box mb={3}>
                  <MultiSelectionInput options={colors} placeholder="Wybierz Kolory" defaultValue={globalColors} onUpdate={setGlobalColors} />
                </Box>
                <Divider my={5} />
                <Box w="100%">
                  <Flex gap="20px" mb="20px">
                    <Text>XS</Text>
                    <MultiSelectionInput options={colors} placeholder="XS" defaultValue={xsColors} onUpdate={setXsColors} />
                    <Text>S</Text>
                    <MultiSelectionInput options={colors} placeholder="S" defaultValue={sColors} onUpdate={setSColors} />
                  </Flex>
                  <Flex gap="20px" mb="20px">
                    <Text>M</Text>
                    <MultiSelectionInput options={colors} placeholder="M" defaultValue={mColors} onUpdate={setMColors} />
                    <Text>L</Text>
                    <MultiSelectionInput options={colors} placeholder="L" defaultValue={lColors} onUpdate={setLColors} />
                  </Flex>
                  <Flex gap="20px">
                    <Text>XL</Text>
                    <MultiSelectionInput options={colors} placeholder="XL" defaultValue={xlColors} onUpdate={setXlColors} />
                    <Text>XXL</Text>
                    <MultiSelectionInput options={colors} placeholder="XXL" defaultValue={xxlColors} onUpdate={setXxlColors} />
                  </Flex>
                </Box>
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleSave}>Zapisz</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ProductTableRow;
