import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Collapse,
  Flex,
  Text,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  VStack,
  PopoverArrow,
  PopoverCloseButton,
  IconButton,
  PopoverFooter,
  Input,
  Textarea,
  Link,
  Tooltip,
} from "@chakra-ui/react";

import React, { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { FaFileInvoice, FaStickyNote } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdLocalShipping } from "react-icons/md";
import { postRequest } from "API";
import { useEffect } from "react";
import Swal from "sweetalert2";

import { getRequest } from "API";

const config = require("../../API/config.json");

const OrderStatusPopover = ({ order, orderStatus, updateStatus }) => {
  const initialFocusRef = React.useRef();

  return (
    <Popover initialFocusRef={initialFocusRef} placement="bottom" closeOnBlur={true}>
      <PopoverTrigger>
        <IconButton aria-label="Search" icon={<BsThreeDotsVertical />} />
      </PopoverTrigger>
      <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Zaaktualizuj status dla #{order.configId}
        </PopoverHeader>
        <PopoverCloseButton />
        <PopoverBody>
          <Flex direction="column" gap="5px">
            <Button colorScheme="green" bg="green.400" w="100%" onClick={() => updateStatus("new", { order: { status: "new" } })}>
              Nowe
            </Button>
            <Button colorScheme="navy" bg="navy.400" w="100%" onClick={() => updateStatus("done", { order: { status: "done" } })}>
              Zrealizowane
            </Button>
            <Button colorScheme="blue" bg="blue.400" w="100%" onClick={() => updateStatus("sent", { order: { status: "sent" } })}>
              Wysłane
            </Button>
            <Button colorScheme="red" bg="red.400" w="100%" onClick={() => updateStatus("cancelled", { order: { status: "cancelled" } })}>
              Anulowane
            </Button>
          </Flex>
        </PopoverBody>
        <PopoverFooter>
          Aktualny Status:{" "}
          <Badge bg={orderStatus.bgStatus} color="white" fontSize="sm" p="3px 10px" borderRadius="8px">
            {orderStatus.status?.toUpperCase()}
          </Badge>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

function NotePopover({ order, updateOrder }) {
  const [note, setNote] = useState(order?.additional_info[0]?.note);
  const [isOpen, setIsOpen] = useState(false);

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleNoteSave = () => {
    setIsOpen(false);
    updateOrder({ id: order.configId, updateData: { additional_info: { note } } });
  };

  return (
    <Popover isOpen={isOpen} onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)}>
      <PopoverTrigger>
        <Button>
          <Tooltip label="Notatka" placement="left">
            <FaStickyNote />
          </Tooltip>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Textarea value={note} onChange={handleNoteChange} placeholder="Enter your note" mb={2} />
          <Button onClick={handleNoteSave} colorScheme="blue">
            Zapisz
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

function TablesTableRow(props) {
  const { order, isLast } = props;
  const [isOpen, setIsOpen] = useState(true);
  const titleColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("gray.100", "navy.700");

  const [orderStatus, setOrderStatus] = useState({
    bgBox: "",
    bgPaymentStatus: "",
    payment_status: "",
    bgStatus: "",
    status: "",
  });

  async function updateOrder(data) {
    try {
      const ordersData = await postRequest(`${config.host}/updateOrder`, data);
    } catch (err) {
      console.error(err);
    }
  }

  async function updateStatus(order_status, updateData) {
    let bgBox, bgPaymentStatus, payment_status, bgStatus, status;

    if (updateData) {
      const data = {
        id: order.configId,
        updateData,
      };

      const ordersData = await postRequest(`${config.host}/updateOrder`, data);

      if (ordersData.error) {
        console.error(ordersData.error);
      }
    }

    if (order_status) {
      switch (order.payment_status) {
        case "STARTED":
          payment_status = "Nieopłacone";
          bgPaymentStatus = "red.400";
          bgBox = "red.200";
          break;
        case "PENDING":
          payment_status = "Oczekiwanie";
          bgPaymentStatus = "yellow.500";
          bgBox = "orange.200";
          break;
        case "COMPLETED":
          payment_status = "Opłacone";
          bgPaymentStatus = "green.400";
          bgBox = "green.100";
          break;
        case "CANCELED":
          payment_status = "Anulowane";
          bgPaymentStatus = "red.500";
          bgBox = "red.200";
          break;
      }

      switch (order_status) {
        case "new":
          status = "Nowe";
          bgStatus = "green.400";
          break;
        case "done":
          status = "Zrealizowane";
          bgStatus = "navy.400";
          bgBox = "navy.200";
          break;
        case "sent":
          status = "Wysłane";
          bgStatus = "blue.400";
          bgBox = "blue.200";
          break;
        case "cancelled":
          status = "Anulowane";
          bgStatus = "red.500";
          bgBox = "red.200";
          break;
      }

      const orderStatus = {
        bgBox,
        bgPaymentStatus,
        payment_status,
        bgStatus,
        status,
      };

      setOrderStatus(orderStatus);
    }
  }

  const printInvoice = async () => {
    const invoiceRequest = await getRequest(`${config.host}/createInvoice?id=${order.configId}`, {}, "blob");

    const downloadUrl = window.URL.createObjectURL(invoiceRequest);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", `faktura_${order.configId}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const getDeliveryLabel = async () => {
    if (order.payment_status != "COMPLETED") {
      return;
    }

    if (!order?.delivery_labels[0]?.number) {
      try {
        // Open swal with loading spinner
        const swalOrder = Swal.fire({
          title: `Tworzenie Etykiety dla ${order.configId}...`,
          allowOutsideClick: true,
          showConfirmButton: false,
        });
        try {
          // Send the GET request using Axios
          const labelRequest = await getRequest(`${config.host}/createlabel?id=${order.configId}`);
          const waybill_number = `data:application/pdf;base64,${labelRequest?.waybill?.response.waybill}`;

          // Create the download button dynamically
          const downloadButton = document.createElement("a");
          downloadButton.href = `data:application/pdf;base64,${waybill_number}`;
          downloadButton.download = `etykieta_${order.configId}.pdf`;
          downloadButton.textContent = "Pobierz Etykietę";

          swalOrder.update({
            title: `Etykieta zrobiona dla ${order.configId}`,
            showCloseButton: true,
            showConfirmButton: true,
            focusConfirm: false,
            customClass: {
              popup: "download-popup-class",
            },
            onClose: () => {
              downloadButton.remove();
            },
            onOpen: () => {
              Swal.getContent().appendChild(downloadButton);
            },
          });
        } catch (error) {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${error?.response?.data}`,
          });
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      let labelRequest = await getRequest(`${config.host}/waybill?id=${order?.delivery_labels[0]?.number}`);
      const waybill_number = labelRequest?.response?.waybill;

      let fetchDataModified = `data:application/pdf;base64,${waybill_number}`;
      let a = document.createElement("a");
      a.href = fetchDataModified;
      a.download = `etykieta_${order.configId}.pdf`;
      a.click();
    }
  };

  const getDeliveryInfo = async () => {
    try {
      const response = await getRequest(`${config.host}/deliveryinfo?id=${order?.delivery_labels[0]?.number}`);

      // Update the existing swal with the data from the API response
      if (response && response.status === 200 && response.response && response.response.order) {
        const orderData = response.response.order;

        Swal.fire({
          title: "Informacje o Dostawie",
          html: `
            <p><strong>Numer Zamówienia:</strong> ${orderData.id}</p>
            <p><strong>Nazwa Usługi:</strong> ${orderData.service_name}</p>
            <hr style="margin-top:5px; margin-bottom:5px;"/>
            <p><strong>Numer Przesyłki:</strong> ${orderData.waybill_number}</p>
            <p><strong>Status:</strong> ${orderData.status}</p>
          `,
          showCancelButton: true,
          showConfirmButton: true,
          showDenyButton: true,
          confirmButtonText: "Link Do Śledzenia",
          denyButtonText: "Pobierz Etykietę",
          cancelButtonText: "Cofnij",
          denyButtonColor: "#2b6cb0",
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(orderData.tracking_url, "_blank");
          } else if (result.isDenied) {
            getDeliveryLabel();
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Ups...",
          text: "Nie udało się pobrać informacji o dostawie!",
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Ups...",
        text: "Wystąpił błąd podczas pobierania informacji o dostawie!",
      });
    }
  };

  useEffect(() => {
    updateStatus(order.status);
  }, []);

  const inputDate = new Date(order.createdAt);

  // Extract the date components
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");

  // Extract the time components
  const hours = String(inputDate.getHours()).padStart(2, "0");
  const minutes = String(inputDate.getMinutes()).padStart(2, "0");

  // Form the formatted date string
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;

  return (
    <Box>
      <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap" cursor="pointer" onClick={() => setIsOpen(!isOpen)} backgroundColor={orderStatus.bgBox} p={4} textColor="gray.900">
        <Checkbox order_id={order.configId} parcel_id="" mr={4} borderColor="#fff" bg="gray.100" _focusVisible="#fff" />
        <Text fontSize="sm">
          ID: <b>#{order.configId}</b>
        </Text>
        <Text fontSize="sm" ml="20px">
          Data zamówienia: <b>{formattedDate}</b>
        </Text>
        <Flex ml="auto" align="center" gap="30px">
          {order?.delivery_labels[0]?.number && (
            <Tooltip label="Numer Etykiety" placement="top">
              <Badge
                bg="purple.500"
                color="white"
                fontSize="xs"
                p="3px 10px"
                borderRadius="8px"
                onClick={(e) => {
                  e.stopPropagation();
                  getDeliveryInfo();
                }}
              >
                {order?.delivery_labels[0]?.number}
              </Badge>
            </Tooltip>
          )}
          <Flex align="center">
            <Text fontSize="md" fontWeight="bold" mr={2}>
              Status Realizacji:
            </Text>
            <Badge bg={orderStatus.bgStatus} color="white" fontSize="sm" p="3px 10px" borderRadius="8px">
              {orderStatus.status?.toUpperCase()}
            </Badge>
          </Flex>
          <Flex align="center">
            <Text fontSize="md" fontWeight="bold" mr={2}>
              Status Płatności:
            </Text>
            <Badge bg={orderStatus.bgPaymentStatus} color="white" fontSize="sm" p="3px 10px" borderRadius="8px">
              {orderStatus.payment_status.toUpperCase()}
            </Badge>
          </Flex>
        </Flex>
      </Flex>
      <Collapse in={isOpen}>
        <Box bg={bgColor} p={4}>
          <Flex alignItems="center" mb={2}>
            <Flex direction="row" w="100%">
              <Box>
                <Flex align="center" gap="10px">
                  <Text fontSize="xl">
                    {order?.address?.first_name} {order?.address?.last_name}
                  </Text>
                  <Text fontSize="xs">{order.address.email}</Text>
                </Flex>
                <Text fontSize="xs" mb={2}>
                  PayU: <b>{order.order_id}</b> | {order.total} PLN
                </Text>
                {order.items.map((item) => (
                  <Flex key={item?.product_id} alignItems="center" mb={2}>
                    <Avatar src={item?.product_image || ""} w="100px" h="150px" borderRadius="12px" me={2} />
                    <Flex direction="column" justify="space-between" gap="30px">
                      <Text fontSize="md" color={titleColor} fontWeight="bold">
                        {item?.product_name}
                      </Text>
                      <Text fontSize="sm" mt="auto" fontWeight="normal">
                        Ilość: <b>{item?.product_quantity}</b>
                        <br />
                        {item?.product_metaValues?.color && (
                          <>
                            Kolor: <b>{item?.product_metaValues?.color?.name}</b>
                          </>
                        )}
                        <br />
                        {item?.product_metaValues?.size && (
                          <>
                            Rozmiar: <b>{item?.product_metaValues?.size?.toUpperCase()}</b>
                          </>
                        )}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </Box>
              <Flex ml="auto" direction="row" my="5" gap="30px">
                <Box>
                  <Box mb="5">
                    <Text fontWeight="bold" mb={2}>
                      Wyślij przed 31/07/2023
                    </Text>
                    <Text fontSize="sm" mb={4}>
                      {order.delivery_method}
                      <br />
                      {order?.address?.delivery_info?.foreign_access_point_id || ""}
                    </Text>
                  </Box>
                  <Text fontWeight="bold" mb={2}>
                    Adres doręczeń:
                  </Text>
                  <Text fontSize="sm" mb={1}>
                    {order?.address?.first_name} {order?.address?.last_name}
                  </Text>
                  <Text fontSize="sm" mb={1}>
                    {order?.address?.address_street}
                  </Text>
                  <Text fontSize="sm" mb={1}>
                    {order?.address?.address_postal_code}, {order?.address?.address_city}
                  </Text>
                  <Text fontSize="sm" mb={1}>
                    {order?.address?.address_country}
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" mb={2}>
                    Informacje Kontaktowe:
                  </Text>
                  <Text fontSize="sm" mb={1}>
                    <b>Imię i Naziwsko:</b> {order?.address?.first_name} {order?.address?.last_name}
                  </Text>
                  <Text fontSize="sm" mb={1}>
                    <b>Email:</b>{" "}
                    <Link href={`tel:${order?.address?.email}`} textDecoration="underline" _hover={{ textDecoration: "underline" }}>
                      {order?.address?.email}
                    </Link>
                  </Text>
                  <Text fontSize="sm" mb={1}>
                    <b>Telefon:</b>{" "}
                    <Link href={`tel:${order?.address?.phone_number}`} textDecoration="underline" _hover={{ textDecoration: "underline" }}>
                      {order?.address?.phone_number}
                    </Link>
                  </Text>
                </Box>
              </Flex>
              <Flex ml="5vw" mr="20px" direction="column" gap="5px" w="50px">
                <Tooltip label="Oznacz jako zrealizowane" placement="left">
                  <IconButton aria-label="Mark order as done" icon={<AiOutlineCheck />} onClick={() => updateStatus("done", { order: { status: "done" } })} />
                </Tooltip>
                <OrderStatusPopover order={order} orderStatus={orderStatus} updateStatus={updateStatus} />
                <NotePopover order={order} updateOrder={updateOrder} />
                <Tooltip label="Pobierz Fakturę" placement="left">
                  <IconButton aria-label="Download the invoice" icon={<FaFileInvoice />} onClick={printInvoice} />
                </Tooltip>
                <Tooltip label="Pobierz Etykietę" placement="left">
                  <IconButton aria-label="Download the shipping label" icon={<MdLocalShipping />} onClick={getDeliveryLabel} />
                </Tooltip>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Collapse>
    </Box>
  );
}

export default TablesTableRow;
