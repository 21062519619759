// import
import React, { Component } from "react";
import Dashboard from "views/Dashboard/Dashboard.js";
import Orders from "views/Dashboard/Orders.js";
import Billing from "views/Dashboard/Billing.js";
import Profile from "views/Dashboard/Profile.js";
import Products from "views/Dashboard/Products.js";
import SignIn from "views/Pages/SignIn.js";

import { HomeIcon, StatsIcon, CreditIcon, PersonIcon, DocumentIcon, RocketIcon } from "components/Icons/Icons";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Zamówienia",
    icon: <StatsIcon color="inherit" />,
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/billing",
    name: "Płatności",
    icon: <CreditIcon color="inherit" />,
    component: Billing,
    layout: "/admin",
  },
  {
    name: "SKLEP",
    category: "shop",
    state: "pageCollapse",
    views: [
      {
        path: "/products",
        name: "Produkty",
        icon: <CreditIcon color="inherit" />,
        component: Products,
        layout: "/admin",
      },
      {
        path: "/categories",
        name: "Kategorie",
        icon: <CreditIcon color="inherit" />,
        component: Billing,
        layout: "/admin",
      },
      {
        path: "/coupons",
        name: "Kupony",
        icon: <CreditIcon color="inherit" />,
        component: Billing,
        layout: "/admin",
      },
    ],
  },
  {
    name: "ACCOUNT PAGES",
    category: "account",
    state: "pageCollapse",
    views: [
      {
        path: "/profile",
        name: "Profile",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
      },
    ],
  },
];
export default dashRoutes;
