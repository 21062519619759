import { useEffect, useState } from "react";
import { Flex, Table, Tbody, Text, Th, Thead, Tr, useColorModeValue, useToast, Checkbox, Select, Button, InputGroup, InputRightElement, Input, IconButton } from "@chakra-ui/react";

import { EditIcon, SearchIcon } from "@chakra-ui/icons";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ProductTableRow from "components/Shop/ProductTableRow";
import { getRequest } from "API/index";
import config from "API/config.json";
import PageNumberSelector from "components/PageNumberSelector";
import { useDebounce } from "use-debounce";

import ProductCreateionModel from "components/Shop/ProductCreateionModel";

function ProductAdminList() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [search, setSearch] = useState("");

  const [sortDirection, setSortDirection] = useState("DESC");
  const [sortOrder, setSortOrder] = useState("createdAt");
  const [selectedCategory, setSelectedCategory] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(1);

  const [attributes, setAttributes] = useState({});

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const toast = useToast();
  const fetchProducts = async (page = 1) => {
    try {
      const urlAttributes = `${config.host}/attributes`;
      const attributesRequest = await getRequest(urlAttributes);

      setAttributes(attributesRequest);

      const queryParams = new URLSearchParams({
        page,
        category: selectedCategory,
        sortDirection,
        sortOrder,
        search: searchQuery,
      });

      const url = `${config.host}/products?${queryParams}`;
      const data = await getRequest(url);
      const { products: newProducts, pagination } = data;

      setProducts(newProducts);
      setTotalPages(pagination.totalPages);
      setTotalCount(pagination.totalItems);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch products.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const url = `${config.host}/categories`;
        const categoriesData = await getRequest(url);
        setCategories(categoriesData);
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to fetch categories.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    };

    fetchProducts();
    fetchCategories();
  }, [searchQuery]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchProducts(page);
  };

  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    const updatedProducts = products.map((product) => ({
      ...product,
      selected: event.target.checked,
    }));
    setProducts(updatedProducts);
  };

  const handleSelectProduct = (index) => {
    const updatedProducts = [...products];
    updatedProducts[index].selected = !updatedProducts[index].selected;
    setProducts(updatedProducts);
  };

  const handleActionChange = (event) => {
    setSelectedAction(event.target.value);
  };

  const handleDeleteSelected = (productId) => {
    const selectedProducts = products.filter((product) => product.selected);
  };

  const handleChangeVisibility = (productId) => {
    const selectedProducts = products.filter((product) => product.selected);
  };

  const handleAddCategory = (productId) => {
    const selectedProducts = products.filter((product) => product.selected);
  };

  const handleRemoveCategory = (productId) => {
    const selectedProducts = products.filter((product) => product.selected);
  };

  const handleAddTag = (productId) => {
    const selectedProducts = products.filter((product) => product.selected);
  };

  const handleRemoveTag = (productId) => {
    const selectedProducts = products.filter((product) => product.selected);
  };

  const handleApplyAction = () => {
    switch (selectedAction) {
      case "delete":
        handleDeleteSelected();
        break;
      case "visibility":
        handleChangeVisibility();
        break;
      case "addCategory":
        handleAddCategory();
        break;
      case "removeCategory":
        handleRemoveCategory();
        break;
      case "addTag":
        handleAddTag();
        break;
      case "removeTag":
        handleRemoveTag();
        break;
      default:
        break;
    }

    setSelectedAction(""); // Reset selected action after applying
  };

  return (
    <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Flex justify="space-between" alignItems="center">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Produkty ({totalCount})
              </Text>
            </Flex>
            <Flex justify="space-between" alignItems="center" mt="50px">
              <Flex>
                <Select placeholder="Select action" variant="outline" value={selectedAction} onChange={handleActionChange} mr={2}>
                  <option value="delete">Delete Selected</option>
                  <option value="visibility">Change Visibility</option>
                  <option value="addCategory">Add Category</option>
                  <option value="removeCategory">Remove Category</option>
                  <option value="addTag">Add Tag</option>
                  <option value="removeTag">Remove Tag</option>
                </Select>
                <Button colorScheme="blue" size="sm" leftIcon={<EditIcon />} isDisabled={!selectedAction} onClick={handleApplyAction}>
                  Apply
                </Button>
              </Flex>
              <Flex>
                <InputGroup ml="auto" w="300px">
                  <Input placeholder="Szukaj" value={search} onChange={(e) => setSearch(e.target.value)} />
                  <InputRightElement>
                    <IconButton aria-label="Search" size="sm" icon={<SearchIcon />} onClick={() => setSearchQuery(search)} />
                  </InputRightElement>
                </InputGroup>
              </Flex>
              <Flex>
                <Button onClick={handleOpenModal}>Dodaj Nowy Produkt</Button>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <Th pl="0px" borderColor={borderColor} color="gray.400">
                    <Checkbox mr="15px" isChecked={selectAll} onChange={handleSelectAll} />
                    Produkt
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Cena
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Kategorie / Tagi
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Status
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Ostatnia Aktualizacja
                  </Th>
                  <Th borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {products.map((row, index, arr) => {
                  return <ProductTableRow product={row} attributes={attributes} isLast={index === arr.length - 1} selected={row.selected || false} onSelect={() => handleSelectProduct(index)} />;
                })}
              </Tbody>
            </Table>
            <PageNumberSelector currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
          </CardBody>
        </Card>
      </Flex>
      <ProductCreateionModel isOpen={isModalOpen} onClose={handleCloseModal} attributes={attributes} />
    </>
  );
}

export default ProductAdminList;
