import { Box } from "@chakra-ui/react";
import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "./styles.css";

const animatedComponents = makeAnimated();

const MultiSelectionInput = ({ options, placeholder, width = "100%", defaultValue, onUpdate }) => {
  const handleSelectionChange = (selectedOptions) => {
    onUpdate(selectedOptions);
  };

  return (
    <Box width={width} className="react-select-selection-div">
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        options={options}
        placeholder={placeholder}
        defaultValue={defaultValue}
        styles={{ width: "auto" }}
        onChange={handleSelectionChange}
      />
    </Box>
  );
};

export default MultiSelectionInput;
