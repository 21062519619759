import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

const ProductSizes = ({ product, squareSize = 20 }) => {
  const { xs_colors, s_colors, m_colors, l_colors, xl_colors } = product;
  const sizes = [];

  if (xs_colors.length > 0) {
    sizes.push("XS");
  }
  if (s_colors.length > 0) {
    sizes.push("S");
  }
  if (m_colors.length > 0) {
    sizes.push("M");
  }
  if (l_colors.length > 0) {
    sizes.push("L");
  }
  if (xl_colors.length > 0) {
    sizes.push("XL");
  }

  return (
    <Flex>
      {sizes.map((size) => (
        <Flex
          key={size}
          border="1px solid"
          borderRadius="100px"
          mr="2"
          w={`${squareSize}px`}
          h={`${squareSize}px`}
          fontSize="10px"
          justifyContent="center"
          align="center"
          cursor="pointer"
          _hover={{ bg: "#000", color: "#fff", border: "1px solid #000" }}
        >
          {size}
        </Flex>
      ))}
    </Flex>
  );
};

export default ProductSizes;
