// Chakra Icons
import { BellIcon } from "@chakra-ui/icons";
// Chakra Imports
import { Box, Button, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Stack, Text, useColorMode, useColorModeValue } from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
import { ArgonLogoDark, ArgonLogoLight, ChakraLogoDark, ChakraLogoLight, ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import { SearchBar } from "components/Navbars/SearchBar/SearchBar";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import React from "react";
import { useHistory } from "react-router-dom";

import routes from "routes.js";
import { BiLogOutCircle } from "react-icons/bi";

import { createClient } from "@supabase/supabase-js";
import settings from "settings.json";

const supabase = createClient(settings.supabase.url, settings.supabase.apiKey);

export default function HeaderLinks(props) {
  const { variant, children, fixed, scrolled, secondary, onOpen, ...rest } = props;

  const { colorMode } = useColorMode();
  const history = useHistory();

  async function singOutUser() {
    const { error } = await supabase.auth.signOut();
    history.push("/");
  }

  // Chakra Color Mode
  let navbarIcon = fixed && scrolled ? useColorModeValue("gray.700", "gray.200") : useColorModeValue("white", "gray.200");
  let menuBg = useColorModeValue("white", "navy.800");
  if (secondary) {
    navbarIcon = "white";
  }
  return (
    <Flex pe={{ sm: "0px", md: "16px" }} w={{ sm: "100%", md: "auto" }} alignItems="center" flexDirection="row">
      <SearchBar me="18px" />
      <SidebarResponsive
        hamburgerColor={"white"}
        logo={
          <Stack direction="row" spacing="12px" align="center" justify="center">
            {colorMode === "dark" ? <ArgonLogoLight w="74px" h="27px" /> : <ArgonLogoDark w="74px" h="27px" />}
            <Box w="1px" h="20px" bg={colorMode === "dark" ? "white" : "gray.700"} />
            {colorMode === "dark" ? <ChakraLogoLight w="82px" h="21px" /> : <ChakraLogoDark w="82px" h="21px" />}
          </Stack>
        }
        colorMode={colorMode}
        secondary={props.secondary}
        routes={routes}
        {...rest}
      />
      <SettingsIcon cursor="pointer" ms={{ base: "16px", xl: "0px" }} me="16px" onClick={props.onOpen} color={navbarIcon} w="18px" h="18px" />

      <BiLogOutCircle color="white" cursor="pointer" onClick={singOutUser} />
    </Flex>
  );
}
