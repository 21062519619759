import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  Box,
  Image,
  Text,
  Input,
  Textarea,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  Divider,
} from "@chakra-ui/react";

import MultiSelectionInput from "./components/MultiSelection";
import { postRequest } from "API";
import axios from "axios";
const config = require("../../API/config.json");

const ProductCreationModal = ({ isOpen, onClose, attributes }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [salePrice, setSalePrice] = useState(0);

  const [tags, setTags] = useState("");
  const [categories, setCategories] = useState([]);

  const [colors, setColors] = useState([]);
  const [xsColors, setXsColors] = useState([]);
  const [sColors, setSColors] = useState([]);
  const [mColors, setMColors] = useState([]);
  const [lColors, setLColors] = useState([]);
  const [xlColors, setXlColors] = useState([]);
  const [xxlColors, setXxlColors] = useState([]);
  const [images, setImages] = useState([]);

  const handleSave = async () => {
    // Prepare the form data
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("sale_price", salePrice);
    formData.append("categories", JSON.stringify(categories));
    formData.append("tags", tags);
    formData.append("colors", JSON.stringify(colors));
    formData.append("xsColors", JSON.stringify(xsColors));
    formData.append("sColors", JSON.stringify(sColors));
    formData.append("mColors", JSON.stringify(mColors));
    formData.append("lColors", JSON.stringify(lColors));
    formData.append("xlColors", JSON.stringify(xlColors));
    formData.append("xxlColors", JSON.stringify(xxlColors));

    console.log("tags", tags);
    // Append each image as a file to the form data
    images.forEach((image, index) => {
      formData.append(`image${index + 1}`, image);
    });

    try {
      axios.defaults.headers.common["key"] = config.key;
      const auth_token = JSON.parse(localStorage?.getItem("sb-dcglcitkyblpqwxjbwva-auth-token"));
      axios.defaults.headers.common["hasdoasd"] = auth_token?.access_token;
      // Send the form data to your API endpoint
      const url = `${config.host}/createProduct`;
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Response from API:", response.data);
      onClose();
    } catch (error) {
      console.error("Error while saving the data:", error);
    }
  };

  const handleImageUpload = (event) => {
    const files = event.target.files;
    if (files && files.length) {
      const imageFiles = Array.from(files).slice(0, 8); // Allow up to 8 images
      const imageUrls = [];
      let counter = 0;

      const handleReaderLoad = () => {
        counter++;
        if (counter === imageFiles.length) {
          setImages(imageUrls);
        } else {
          readNextImage();
        }
      };

      const readNextImage = () => {
        const file = imageFiles[counter];
        const reader = new FileReader();
        reader.onloadend = () => {
          imageUrls.push(reader.result);
          handleReaderLoad();
        };
        reader.readAsDataURL(file);
      };

      readNextImage();
    }
  };

  const handleImageRemove = (indexToRemove) => {
    setImages((prevImages) => prevImages.filter((_, index) => index !== indexToRemove));
  };

  const colorsAttributes = attributes?.colors?.map((color) => {
    return { configId: color.configId, label: color?.name, value: color.configId };
  });

  const categoriesAttributes = attributes?.categories?.map((category) => {
    return { configId: category.configId, label: category?.name, value: category.configId };
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Flex>
            <Box flex="0 0 200px" borderRight="1px solid #cccfd4" pr={5} pt={5}>
              <Box>
                <Text>Dodaj Zdjęcia (Maks. 8)</Text>
                <input type="file" multiple onChange={handleImageUpload} />
                {images.map((image, index) => (
                  <Box key={index} mt={2} position="relative">
                    <Image src={image} alt={`Product Image ${index}`} borderRadius="md" />
                    <Button size="sm" onClick={() => handleImageRemove(index)} position="absolute" top="5px" right="5px">
                      Remove
                    </Button>
                  </Box>
                ))}
              </Box>
              <Flex direction="column" mt="200px" gap="10px">
                <MultiSelectionInput options={categoriesAttributes} placeholder="Wybierz Kategorie" defaultValue={categories} onUpdate={setCategories} />
                <Input placeholder="Tags" defaultValue={tags} onChange={(e) => setTags(e.target.value)} />
              </Flex>
            </Box>
            <Box flex="1" p={5}>
              <Box mb={3}>
                <Text>Nazwa przedmiotu</Text>
                <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
              </Box>
              <Box mb={3}>
                <Text>Opis</Text>
                <Textarea rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
              </Box>
              <Flex mb={3}>
                <Box flex="1" mr={3}>
                  <Text>Cena</Text>
                  <InputGroup>
                    <InputLeftAddon children="PLN" />
                    <NumberInput value={price} min={0} onChange={(e) => setPrice(e)}>
                      <NumberInputField required />
                    </NumberInput>
                  </InputGroup>
                </Box>
                <Box flex="2" mr={3}>
                  <Text>Cena Promocyjna</Text>
                  <InputGroup>
                    <InputLeftAddon children="PLN" />
                    <NumberInput value={salePrice} onChange={(e) => setSalePrice(e)}>
                      <NumberInputField required />
                    </NumberInput>
                  </InputGroup>
                </Box>
              </Flex>
              <Divider my={5} />
              <Box mb={3}>
                <MultiSelectionInput options={colorsAttributes} placeholder="Wybierz Kolory" defaultValue={colors} onUpdate={setColors} />
              </Box>
              <Divider my={5} />
              <Box w="100%">
                <Flex gap="20px" mb="20px">
                  <Text>XS</Text>
                  <MultiSelectionInput options={colorsAttributes} placeholder="XS" defaultValue={xsColors} onUpdate={setXsColors} />
                  <Text>S</Text>
                  <MultiSelectionInput options={colorsAttributes} placeholder="S" defaultValue={sColors} onUpdate={setSColors} />
                </Flex>
                <Flex gap="20px" mb="20px">
                  <Text>M</Text>
                  <MultiSelectionInput options={colorsAttributes} placeholder="M" defaultValue={mColors} onUpdate={setMColors} />
                  <Text>L</Text>
                  <MultiSelectionInput options={colorsAttributes} placeholder="L" defaultValue={lColors} onUpdate={setLColors} />
                </Flex>
                <Flex gap="20px">
                  <Text>XL</Text>
                  <MultiSelectionInput options={colorsAttributes} placeholder="XL" defaultValue={xlColors} onUpdate={setXlColors} />
                  <Text>XXL</Text>
                  <MultiSelectionInput options={colorsAttributes} placeholder="XXL" defaultValue={xxlColors} onUpdate={setXxlColors} />
                </Flex>
              </Box>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSave}>Zapisz</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProductCreationModal;
