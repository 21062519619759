import React, { useState } from "react";
import { Button, ButtonGroup, Box, useToast } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const PageNumberSelector = ({ currentPage, totalPages, handlePageChange }) => {
  const [isCooldownActive, setIsCooldownActive] = useState(false);
  const toast = useToast();

  const handlePageClick = (pageNumber) => {
    if (isCooldownActive) {
      toast({
        title: "Cooldown",
        status: "error",
        duration: 1000,
        isClosable: false,
      });
    } else {
      setIsCooldownActive(true);
      handlePageChange(pageNumber);
      setTimeout(() => {
        setIsCooldownActive(false);
      }, 100);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      handlePageClick(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageClick(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Button key={i} size="sm" onClick={() => handlePageClick(i)} isActive={i === currentPage} cursor="pointer" border="1px solid">
          {i}
        </Button>
      );
    }

    return pageNumbers;
  };

  return (
    <Box textAlign="center" my={4}>
      <ButtonGroup isAttached variant="outline" size="sm">
        <Button size="sm" leftIcon={<ChevronLeftIcon />} isDisabled={currentPage === 1} onClick={handlePreviousPage} cursor="pointer" />
        {renderPageNumbers()}
        <Button size="sm" rightIcon={<ChevronRightIcon />} isDisabled={currentPage >= totalPages} onClick={handleNextPage} cursor="pointer" />
      </ButtonGroup>
    </Box>
  );
};

export default PageNumberSelector;
