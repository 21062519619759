import { createClient } from "@supabase/supabase-js";
import settings from "../settings.json";

const supabase = createClient(settings.supabase.url, settings.supabase.apiKey);

export const getUserData = async (force, skipRequest) => {
  const user = await supabase.auth.getUser();

  console.log(user);

  return user;
};
