import axios from "axios";
import config from "API/config.json";

axios.defaults.headers.common["key"] = config.key;
const auth_token = JSON.parse(localStorage?.getItem("sb-dcglcitkyblpqwxjbwva-auth-token"));
axios.defaults.headers.common["hasdoasd"] = auth_token?.access_token;

export const getRequest = async (url, headers, responseType) => {
  try {
    const response = await axios.get(url, { headers, responseType });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error making GET request:", error);
    throw error;
  }
};

export const postRequest = async (url, data, headers, responseType) => {
  try {
    const response = await axios.post(url, data, { headers, responseType });
    return response.data;
  } catch (error) {
    console.error("Error making POST request:", error);
    throw error;
  }
};
